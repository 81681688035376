@media (min-width: 360px) {
  .contact {
    margin-left: 5px;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .cnct_screen_ctn {
    background-repeat: no-repeat;
  }

  .contact-title {
    margin-top: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: #3af6ff;
    font-size: 38px;
  }

  .contact-title h2 {
    color: #3af6ff;
  }

  .contact-title p {
    color: white;
    margin: 0;
    text-align: center;
    margin-bottom: 50px;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    background: linear-gradient(
      rgba(58, 246, 255, 0.2) 30%,
      rgba(185, 4, 139, 0.2) 60%
    );
    border-radius: 1rem;
    box-shadow: 8px 8px 10px rgba(1, 13, 79, 58%);
    row-gap: 5px;
  }

  .contact-form label {
    color: white;
    margin: 20px 0 0 30px;
    text-transform: uppercase;
  }

  .contact-form .contact-inputs,
  .contact-form textarea {
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 6px;
    padding: 12px 16px;
    border: 1px solid #ccc;
    width: calc(100% - 60px); 
    box-sizing: border-box; 
  }

  .contact-form textarea {
    height: 150px;
    resize: none; 
    overflow: auto; 
  }

  .contact .buttonContainer {
    display: flex;
    justify-content: flex-start;
    margin-left: 30px;
  }

  .contact .contact-button {
    color: white;
    width: 220px;
    height: 50px;
    background: linear-gradient(
      rgba(58, 246, 255, 0.2) 30%,
      rgba(185, 4, 139, 0.2) 60%
    );
    border-radius: 3rem 0 3rem 0;
    text-decoration: none;
    border: none;
    cursor: pointer;
    margin: 30px 100px 25px 0;
    border-radius: 50px;
    box-shadow: 8px 8px 10px rgba(1, 13, 79, 58%);
    font-weight: bold;
    font-size: 14px;
  }
}

@media (min-width: 900px) {
  .contact-form .contact-inputs,
  .contact-form textarea {
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100% - 100px); 
  }

  .contact-form label {
    text-align: justify;
    color: white;
    margin: 20px 0 0 50px;
    text-transform: uppercase;
  }

  .contact-form textarea {
    margin: 0 0 20px 50px;
    width: calc(100% - 100px);
    max-width: 600px; 
  }
}

@media (min-width: 1024px) {
  .contact-form {
    width: 55%;
  }

  .contact .buttonContainer {
    display: flex;
    justify-content: flex-start;
    width: 80%;
    margin-left: 50px;
  }

  .footer_contact {
    display: flex;
    margin-top: 500px;
    position: relative;
  }

  .contact-form .contact-inputs,
  .contact-form textarea {
    width: 100%;
    max-width: 600px; 
  }
}
