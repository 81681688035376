.flip-card {
  display: flex;
  justify-content: center;
  margin: 30px 10px 30px 10px;
  width: 280px;
  height: 330px;
  -webkit-perspective: 1000px;
  perspective: 1000px; 

}
.filters__.rotate {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  
}

.title_flip {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: -8px;
  color: white;
}

.sub {
  font-size: 21px;
  font-weight: bold;
  color: rgba(185, 4, 139, 0.915) !important;
}
.des_3_flip {
  color: rgba(185, 4, 139, 0.9154228855721394);;

  .agregar-carrito2{
    color: aqua;
  }

}
.flip-card-inner {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  color: aqua;
  font-weight: bold;
}
.card_btn_ctn {
  text-align: center;
  margin-top: 20px;
}
.card_btn {
  margin-top: 20px;
  background: none;
  font-size: 16px;
  font-weight: bold;
  border: none;
  color: #3af6ff;
  cursor: pointer;
}
.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  
}
.front_items {
  margin-top: 30px;
}
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  
}

.flip-card-front {
  background: linear-gradient(rgba(58, 246, 255, 0.2) 30%, rgba(185, 4, 139, 0.2) 60%);
  border-radius: 20px;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg); /* para bug firefox */
  border-radius: 30% 0% 30% 0%;
  width: 270px;
  height: 270px;


}
.flip-card-front_items {
  margin: 20px 10px 30px 10px;
  
}

.flip-card-back {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(rgba(58, 246, 255, 0.2) 30%, rgba(185, 4, 139, 0.2) 60%);
  width: 330px;
  height: 330px;
  color: white;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  border-radius: 0% 30% 0% 30%;
  overflow: hidden; /* Oculta el texto que sobresalga del contenedor */
}
.flip-card-back p {
  margin: 5px 15px;
  font-size: 12px;
  font-weight: bold;
}
.des_3 {
  font-weight: bold;
  
}
.sub {
  color: white;
  text-align: center;
  margin: 5px 10px 0px 10px;
}

@media (max-width: 830px) {
  .flip-card {
 
  }
  .front_items img {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .flip-card {
 
  }
  .des_3 {
    margin-top: 15px;
    font-size: 18px;
    
  }
  .sub {
    margin: 10px 10px 0px 10px;
  }
}

@media (max-width: 434px) {
  .flip-card {
    width: 280px;
    height: 350px;
  }
  .des_3_flip p {
    font-size: 18px;
    font-weight: bold;
  }
  .flip-card-back p {
  
    font-weight: bold;
  }
  .front_items img {
 
    margin-bottom: 10px;
  }


}
