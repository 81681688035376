.CTN_ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.CTN_ctn2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
}
.CTN_title {
  color: #3af6ff;
  text-align: center;
  margin-bottom: 40px;
}
.ctn_button {
  box-shadow: 8px 8px 10px rgba(1, 13, 79, 58%);
  font-weight: bold;
  color: white;
  font-size: 22px;
  width: 250px;
  height: 60px;
  text-decoration: none;
  background: linear-gradient(
    rgba(58, 246, 255, 0.2) 30%,
    rgba(185, 4, 139, 0.2) 60%
  );
  border-radius: 50px;
  border: none;
  cursor: pointer;
  margin: 0px 0 20px 0;
}
.link_ctn {
  color: aliceblue;
  text-decoration: none;
  font-weight: bold;
}
.ctn_p {
  color: white;
  font-weight: bold;
  text-align: center;
}
.ctn_p p {
  font-size: 22px;
}

@media (max-width: 1024px) {
  .ctn_button {
    width: 220px;
    height: 70px;
  }

  .CTN_ctn2 {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .CTN_title {
    margin-bottom: 30px;
  }
  .CTN_ctn {
  }

  .CTN_ctn p {
    margin-top: 25px;
  }
  .CTN_ctn2 {
    margin-top: 50px;
    margin-bottom: 0px;
  }
}
@media (max-width: 513px) {
  .CTN_title {
  }
  .CTN_ctn p {
    font-size: 22px;
  }
}
@media (max-width: 460px) {
  .ctn_button {
    width: 200px;
    height: 60px;
    font-size: 20px;
  }
  .CTN_title {
  }
  .ctn_p {
    font-size: 20px;
    margin: 0 7% 0% 7%;
  }
}
@media (max-width: 375px) {
  .CTN_title {
    margin: 0 0% 30px 0%;
  }
  .CTN_ctn p {
    font-size: 18px;
  }
  .ctn_p {
    margin: -15px 5% 7% 7%;
  }
  .CTN_ctn2 {
  }
}
