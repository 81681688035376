.footer_container {
  z-index: 2;
  width: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: flex-start;
}
.footer_elements {
  margin-left: 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 10px;
}
.footer_container li {
  font-size: 14px;
}
.logo_calu {
  display: -webkit-box-align;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -5px;
}
.logo_calu p {
  color: white;
  font-size: 10px;
  font-weight: bold;
  margin-top: -8%;
  white-space: nowrap;
}
.servicios {
  color: white;
  font-size: 20px;
}
.servicios h1 {
  margin: 20px 0 0 32px;
  font-size: 22px;
}
.servicios_ {
  display: flex;
  flex-direction: row;
}

.servicios_ span::first-letter {
  text-transform: uppercase;
}
.verMasFooter {
  display: flex;
  justify-content: center;
  margin-top: -15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.redes {
  font-size: 20px;
  color: rgb(250, 249, 249);

  display: flex;
  flex-direction: column;
}
.redes h1 {
  font-size: 22px;
}
.logo_calu img {
  width: 190px;
  margin-top: -10px;
}

.social_icons img {
  width: 40px;
  height: 40px;
  margin: 0px 10px 0 0;
}

.servicios li {
  font-size: 16px;
  color: #eb39d9;
}

li span {
  color: white; /* text color */
}
.terminos {
  color: white;
  cursor: pointer;
}

.terminos span {
  font-size: 10px;
  position: absolute;
  width: 300px;
}

.terminos .negrita {
  color: #3af6ff;
}

.filter-ctn .terms-content,
.filter-ctn-mbl .terms-content {
  width: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1400px) {
  .footer_container {
    bottom: 0;
  }
}
@media (max-width: 1178px) {
  .footer_container li {
    margin: 0 5px;
    font-size: 14px;
  }
  .social_icons img {
    width: 35px;
    height: 35px;
  }

  .logo_calu p {
    margin: -20px 0 0 20px;
  }
}
@media (max-width: 1024px) {
  .verMasFooter {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .servicios h1 {
    margin: 20px auto;
    font-size: 22px;
  }
  .footer_container {
    display: flex;
    bottom: 0;
    flex-direction: column;
  }
  .logo_calu p {
    margin: auto;
  }
  .servicios_ {
    display: flex;
  }

  .social_icons img {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 1024px) {
  .footer_container {
    flex-direction: column;
    height: 510px;
  }
  .logo_calu {
    display: flex;
    flex-direction: column;
  }
  .logo_calu img {
    width: 200px;
  }

  .logo_calu p {
    margin-top: -20px;
  }

  ul {
    margin-top: -20px;
    margin-right: 10px;
  }

  .servicios {
    margin-top: -10px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .servicios h1 {
    font-weight: 100;
    margin-bottom: 40px;
    font-size: 20px;
  }

  .redes {
    font-size: 20px;
    color: rgb(250, 249, 249);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .redes h1 {
    margin-top: 20px;
    font-weight: 100;
    font-size: 20px;
  }
}

@media (max-width: 464px) {
  .footer_container li {
    font-size: 12px;
  }
}
