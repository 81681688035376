.lateralBar {
  display: flex;
  overflow: auto; /* Usa 'auto' para permitir el scroll sin ocultar el overflow */
  margin: auto;
  margin: 0 auto 50px auto;
}

/* Ocultar flechas de desplazamiento en WebKit (Chrome, Safari) */
.lateralBar::-webkit-scrollbar {
  width: 12px; /* Ancho de la barra de desplazamiento vertical */
  height: 12px; /* Altura de la barra de desplazamiento horizontal */
}

.lateralBar::-webkit-scrollbar-button {
  display: none; /* Oculta las flechas de desplazamiento */
}

.lateralBar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.lateralBar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.lateralBar .lateralContainer {
  color: white;
}

/* Otros estilos */
.lateralBar .lateralContainer p {
}

hr {
  color: rgba(255, 255, 255, 0.8);
  margin-top: 5px;
  max-width: 100px;
}

.lastBlogs {
  display: flex;
}

.lastBlogs .last {
  cursor: pointer;
}

.lastBlogs img {
  width: 200px;
}

.sb_blog_title {
  font-weight: bold;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.sb_blog_title a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin: 0 auto 0 auto;
}

.sb_blog_image {
  max-width: 250px;
  height: 140px;
}

.sb_blog_image img {
  height: 120px;
  width: 130px;
}

.sidebarSlider .arrowL {
  margin-top: -50px;
  width: 30px;
}

.sidebarSlider .arrowR {
  margin-top: -50px;
  width: 30px;
}

@media (min-width: 340px) {
  .lateralBar {
    position: initial;
    display: flex;
    flex-direction: column;
    border-top: 2px solid #c5c5c5;
    margin: auto;
    margin: 150px 0 50px 0;
  }
  
  .sb_blog_image {
    margin: 10px;
  }

  .lateralBar .blogTitle {
    margin: 15px 0;
  }

  .lateralBar .lateralContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    overflow-x: auto;
  }

  .lateralBar .lateralContainer .card_our {
    max-width: 150px;
    height: 150px;
  }

  .lateralBar .lateralContainer .card_our .card_btn .button_portfolio {
    position: initial;
  }
}

@media (min-width: 900px) {
  .lateralBar .lateralContainer .card_our {
    max-width: 250px;
    height: 250px;
  }
}

@media (min-width: 1920px) {
  .lateralBar {
    min-height: 800px;
    top: 15%;
  }
}
