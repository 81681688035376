.paymentCtn {
  min-height: 100vh;
}

.payment-options {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 70%;
  margin: 0 auto;
}

.payment-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title-payment {
  display: flex;
  justify-content: center;
  color: aqua;
  margin-top: 150px;
}

.payment-option {
  width: 200px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  margin: 30px 20px;
  border-radius: 30% 0% 30% 0%;
  width: 210px;
  height: 210px;
  background: linear-gradient(rgba(58, 246, 255, 0.2) 30%, rgba(185, 4, 139, 0.2) 60%);
}

.payment-option img {
  width: 160px;
  height: 140px;
  margin-top: 30px;
}

.payment-option:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.wallet-button-Atv_hz.svelte-15s5jlf {
  width: 300px !important;
  max-width: 100%;
  margin: 0 auto;
}

.grey-3TbucO.svelte-1wbwr8x.svelte-1wbwr8x{
  display: none;
}
#wallet_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; 
  padding-top: 20px; 
}

@media (max-width: 1248px) {
  .payment-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

 

  
  .paymentSlider {
    margin-top: 10%;
  }
}

@media (max-width: 624px) {
  .title-payment {
    display: flex;
    justify-content: center;
  }
}
