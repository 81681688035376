.createPostPage {
  width: 100%;
  display: grid;
  place-items: center;
}

.cpContainer {
  width: 500px;
  margin-top: 100px;
  height: auto;
  padding: 20px;
  background-color: black;
  border-radius: 12px;
  color: white;
  display: flex;
  flex-direction: column;
}

.cpContainer h1 {
  text-align: center;
}

.cpContainer label {
  font-size: 25px;
}
.cpContainer .inputGp {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  white-space: "per-line";
}

.inputGp input,
.inputGp textarea {
  font-size: 18px;
  border: none;
  border-radius: 2px;
  padding: 5px;
}

.inputGp input {
  height: 40px;
}
.inputGp textarea {
  height: 150px;
}

.cpContainer button {
  margin-top: 20px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}
input,
textarea {
  margin-top: 5px;
}
@media (max-width: 560px) {
  .cpContainer{
    width: 90%;
  }
}