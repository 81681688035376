.services {
  display: flex;
  flex-direction: column;
 
  justify-content: center;
  align-items: center;
}
.crud-ctn{
  min-height: 100vh;
}
.crud-ctn2{
  display: flex;
  flex-direction: column;
 
  justify-content: center;
  align-items: center;
  margin: 100px auto;

}
.crudContainer {
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  height: 800px;
  width: 80%;
  background: rgba(255, 255, 255, 0.6);
}

.crudTags {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.crudContainer li {
  color: black;
  display: flex;
  list-style-type: none;
}

.crudItems {
  display: flex;
  flex-wrap: wrap;
  
}

.crudDescription {
  display: flex;
  border: 1px solid #606060;
  align-items: center;
}
.crudBlur {
  filter: blur(3px);
  background: rgba(0, 0, 0, 0.5);


}
.crudBlur .crudItems {

  overflow: scroll;


}
.crudTitle {
  text-align: center;
  font-size: 12px;
  width: 40%;
  margin-left: 5%;
}
.crudText {
  font-size: 15px;
  display: flex;
  justify-content: center;
  margin: 0;
  width: 50%;
}
.crudImage {
  width: 25%;
}
.crudImage img {
  width: 70%;
}
.modal {
  width: 50%;
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
 
  right: 25%;
}
.modal h2 {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  cursor: pointer;
}
.modal form {
  display: flex;
  flex-direction: column;
  text-align: center;
}
@media (max-width: 768px) {
  .modal {
   top: 15%;
   left: 5%;
   width: 90%;
  }
}