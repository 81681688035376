.view {
  display: flex;
  justify-content: center;
}

.blogView-sidebar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.blogTitle {
  text-align: center;
  color: aqua;
}

.mini-description,
.aditional-content {
  white-space: pre-wrap;
  font-weight: normal;
}

.blogCard {
  color: white;
  padding: 2rem;
  row-gap: 20px;
  display: flex;
  flex-direction: column;
}

.blogImg {
  width: 30%;
}

.blogView .contact_ctn .arrow_up {
  position: fixed;
}

.contact_ctn .arrow_up {
  position: fixed;
}

.blogCard .blogText p {
  font-size: 16px;
}

.BlogView {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (min-width: 360px) {
  .banner img {
    width: 100;
    height: auto;
    box-shadow: 8px 8px 10px rgba(1, 13, 79, 58%);
    display: flex;
    margin: 0 auto 0 auto;
  }

  .blogTitle {
    margin-top: 110px;
    color: aqua;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .blogContainer {
    flex-wrap: wrap;
  }

  .blogCard {
    border-radius: 15px;
  }

  .blogImg {
    margin-left: auto;
    margin-right: auto;
    width: 320px;
    border-radius: 15px;
  }

  .date-tagContainer {
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .date-tagContainer hr {
    width: 100%;
  }

  .date-tags {
    color: white;
    display: flex;
  }

  .ctn .CTN_ctn {
    height: initial;
  }

  .ctn .CTN_ctn .CTN_title {
    margin: 25px 0 25px;
  }

  .ctn .CTN_ctn .CTN_ctn2 p {
    margin: 0;
  }

  .footer-blogView .footer_container {
    position: relative;
    z-index: 0;
  }

  .blogCard .youtubePlayer {
    height: 200px;
    margin: 70px auto 150px auto;
  }

  .youtubePlayer iframe {
    width: 90%;
    height: 250px;
    margin-left: 5%;
  }

  .lateralBar {
    display: flex;
    flex-direction: column;
    max-width: 390px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

@media (min-width: 500px) {
  .lateralBar {
    max-width: initial;
  }
}

@media (min-width: 900px) {
  .blogContainer {
    justify-content: center;
    margin: 0 auto 0 auto;
  }

  .blogImg {
    display: block;
    margin: 0 auto;
  }

  .blogCard .blogText h2 {
    text-align: center;
    margin: 0;
    font-size: larger;
  }

  .banner img {
    height: 290px;
    box-shadow: 8px 8px 10px rgba(1, 13, 79, 58%);
  }

  .blogCard .youtubePlayer {
    margin-left: 5%;
    justify-content: center;
    height: 300px;
    width: 100%;
  }

  .youtubePlayer iframe {
    width: 90%;
    height: 380px;
    margin-left: 0%;
  }

  .lateralBar {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

@media (min-width: 1025px) {
  .blogContainer {
    width: 95%;
  }

  .youtube iframe {
    width: 800px;
    height: 300px;
  }
}

@media (min-width: 1174px) {
  .BlogView .lateralContainer {
    width: 350px;
    flex-direction: column;
    border-top: none;
  }

  .BlogView .lateralBar {
    border-top: none;
    border-left: 2px solid #c5c5c5;
  }

  .blogContainer {
    width: 60%;
  }

  .youtube iframe {
    width: 800px;
    height: 300px;
  }
}

.banner {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}

.banner img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}

@media (min-width: 360px) and (max-width: 900px) {
  .banner img {
    width: 90vw;
    height: auto;
  }
}

@media (min-width: 900px) {
  .banner {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
  }
  
  .banner img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
  }
}

@media (min-width: 1025px) {
.banner {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}

.banner img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}
}
