.blog .arrow_up {
  position: fixed;
}

.blog-sidebar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}


.BlogPage {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-bottom: 150px;
}
.icons_novedades {
  width: 130px;
  height: 130px;
}
.blogTitle_ {
  text-align: center;
  margin-top: 59px;
  margin-bottom: 50px;
  color: aqua;
}
.lateralBarTitle {
  text-align: center;
  margin-top: 30px;
  color: aqua;
  font-size: 20px;
}
.postContainer {
  /*-webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(2, 2, 73, 50%);
  box-shadow: 5.5px 5px 10px rgb(61, 59, 59);
  border-radius: 20px;*/
  width: 90%;
  padding: 0 5%;
  flex-wrap: wrap;
  align-items: flex-start;
  min-width: initial;
}

.cardContainerblog {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.card-blog {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: flex-start;
  cursor: pointer;
}

.card-blog  span {

  color: rgba(255, 255, 255, 0.7);
}

.card-blog h2 {
  color: white;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-weight: normal;
  width: 70%;
  text-align: center;
}

.card-blog .blogImage img {
  /*  border-radius: 30% 30% 0 0;*/

  object-fit: cover;
  object-position: top;
  margin: 0;
}

.card-blog .blogImage .description {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
  margin-top: 10px;
  text-align: center;
  margin: 10px auto 0 auto;
}

.card-blog .leerMas {
  color: #eb39d9;
  position: relative;
  font-weight: bold;
}

.titleblog {
  margin: 0;
}

.postContainer .cardContainerblog .card-blog {
  height: auto;
  padding: 10px;
  margin-top: 10px;
}
.card-blog .blogImage {
  display: flex;
  justify-content: center;
}

.card-blog .blogImage .title_news {
  font-size: 19px;
  text-align: center;
  width: 100%;
}
.card-blog .cardHeaderblog {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-blog .cardHeaderblog p {
  width: 60%;
  font-size: 18px;
}
.card-blog .cardHeaderblog .titleblog {
  display: flex;
  justify-content: center;

  width: 100%;
}
.card-blog .cardHeaderblog h2 {
  width: 60%;
}
.card-blog .cardHeaderblog .deleteblog {
 
  display: flex;
 justify-content: center;
}

.card-blog .blogImage .arrow {
  width: 60px;
  height: 0;
  display: flex;
  margin: 0 170px;
}
.card-blog .blogImage .arrow img {
  width: 30px;
  height: 60px;
  margin: 0;
}
.card-blog .leerMas {
  position: initial;
  text-align: center;
}

.card-blog .cardTextblogContainer {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
}
.card-blog h3 {
  font-size: 15px;
}
.card-blog .viewButton {
  background: none;
  border: none;
  cursor: pointer;
  text-align: start;
}



.description {
  font-size: 16px;
}
.vermas-btn{
  display: flex;
  justify-content: center;
  margin: 60px 4% 0 0 ;
}
@media (max-width: 1024px) {

}
@media (max-width: 768px) {
  .blogTitle_ {
    margin-top: 95px;
  
  }
  
}
@media (min-width: 768px) {
  
  .postContainer .cardContainerblog {
    justify-content: center;
  }
  .postContainer .cardContainerblog .cards {
    width: 45%;
  }

  .BlogPage {
    display: flex;
    align-items: center;
    margin-top: 40px;
  }
  .postContainer {
    min-height: 400px;
  }
  .postContainer .cardContainerblog .card-blog {
    width: 250px;
  }
  .card-blog .blogImage .arrow {
    width: 100px;
    height: 60px;
    position: absolute;
    margin-left: 130px;
  }
  .card-blog .blogImage .description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 40px;
    margin-top: 10px;
    text-align: center;
    margin: 10px auto 0 auto;
  }
  .card-blog .leerMas {
    position: absolute;
    width: 48%;
    text-align: end;
    margin-top: 20px;
    font-size: 19px;
  }
  .card-blog h2 {
    width: auto;
    margin: 0;
  }
  .card-blog .blogImage .arrow img {
    width: 50px;
  }

  .card-blog p {
    font-size: 18px;
    margin: 0;
    width: 80%;
  }

  .card-blog .cardHeaderblog .deleteblog {
    display: flex;
    justify-content: space-around;
    width: initial;
  }
  .card-blog .cardTextblogContainer {
    font-size: 3rem;
    margin: 0;
  }

  .card-blog button {
    font-size: 16px;
    width: 100px;
    margin: 10px;
  }

  .blog-sidebar .lateralBar {
    margin-top: 150px;
  }


}

@media (min-width: 1090px) {
  .postContainer {
    min-height: 800px;
    margin-left: 50px;
  }
  .icons_novedades {
    width: 145px;
    height: 145px;
  }
  .blog-sidebar {
    flex-wrap: nowrap;
  }
  .blog-sidebar .lateralBar {
    margin-top: 40px;
  }
  .postContainer .cardContainerblog {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: space-around;
  }
  .postContainer .cardContainerblog .card-blog {
    width: 130px;
  }
  .postContainer .cardContainerblog .card-blog .cardHeaderblog {
    align-items: center;
    margin-right: 50px;
  }
  .postContainer .cardContainerblog .card-blog .cardHeaderblog .titleblog {
    justify-content: center;
  }

  .card-blog .blogImage h2 {
    margin: 0;
    width: auto;
  }
  .card-blog .blogImage .card_news {
    width: 200px;
    margin-right: 50px;
  }
  .card-blog .blogImage .card_news .card_f_news {
    width: 230px;
    height: 225;
  }
  .card-blog .blogImage .arrow {
    margin-left: 200px;
  }
  .card-blog button {
  justify-content: center;
  }
  .card-blog .leerMas {
    width: 430px;
    font-size: 24px;
  }
  .postContainer .card-blog .cardHeaderblog p {
    width: 65%;
  }
  .postContainer .cardContainerblog .card-blog .blogImage {
    justify-content: center;
    align-items: center;
  }
  .blog-sidebar .lateralBar {
    width: 350px;
border-left: 2px solid #c5c5c5 ;
border-top: none;
    margin-right: 25px;
  }
  .lateralBar .blogTitle_ {
    margin: 0;
    margin-top: 25px;
  }
  .blog-sidebar .lateralBar .lateralContainer {
    margin-top: 60px;
    flex-direction: column;
  }
  .card-blog .card_news{
    margin-left: 0;
  }
}
.card-blog span {
  display: flex;
  justify-content: center;
    color: rgba(255, 255, 255, 0.7);
  }
@media (min-width: 1090px) {
  
  .card-blog span {
   margin-right: 40%;
      color: rgba(255, 255, 255, 0.7);
    }
}
