/* Estilos para la tabla */
.table-container {
    margin: auto;
    border-collapse: collapse;
    width: 80%;
    background-color: aliceblue;
  }
  
  /* Estilos para las celdas de la tabla */
  .table-container th,
  .table-container td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  .table-container th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

.email-list-ctn h2{
  text-align: center;
  color: aqua;
}