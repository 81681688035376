@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

@font-face {
  font-family: 'Archive';
  src: url('./Archive-Regular.ttf');
}
html {
  scrollbar-color: rgba(5, 0, 75, 1) rgba(5, 0, 75, 0);
  font-family: 'Montserrat', sans-serif;
}
body {
  font-family: 'Montserrat', sans-serif;
}

.video-background video {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
  z-index: -1;
}
.container_ {
  background: rgba(4, 25, 88, 0.6) 100%;
}
section {
  margin-top: 12%;
}

iframe[src^="https://www.google.com/recaptcha/"]
{
  display: none;
}

.grecaptcha-badge {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 24px;
    font-family: 'Archive', Arial, sans-serif;
  }
  h2 {
    font-size: 18px;
  }

  section {
    margin-top: 20px;
    margin-bottom: 150px;
  }
}

@media only screen and (max-width: 390px) {
  h1 {
    font-size: 24px;
    font-family: 'Archive', Arial, sans-serif;
  }
  h2 {
    font-size: 18px;
  }

}
/* Estilos para tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  h1 {
    font-size: 28px;
    font-family: 'Archive', Arial, sans-serif;
  }
  h2 {
    font-size: 18px;
  }
  p {
    font-size: 15px;
  }
  section {
    margin-bottom: 150px;
  }
}

/* Estilos para escritorio */
@media only screen and (min-width: 1024px) {
  h1 {
    font-size: 30px;
    font-family: 'Archive', Arial, sans-serif;
  }
  h2 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
  }
}
.contenedor {
  margin: 2rem auto;
  border: 1px solid #aaa;
  height: 300px;
  width: 90%;
  max-width: 400px;
  background: #f1f2f3;
  overflow: auto;
  box-sizing: border-box;
  padding: 0 1rem;
}

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(5, 0, 75, 1);
  border-radius: 20px;
  width: 10px;
}
.thumb-vertical {
  width: 25px;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(5, 0, 75, 1);
}

/* Ocultar flechas de desplazamiento en WebKit (Chrome, Safari) */
::-webkit-scrollbar-button {
  display: none;
}

/* Estilos opcionales para la barra de desplazamiento */
::-webkit-scrollbar {
  width: 12px; /* Ancho de la barra de desplazamiento vertical */
  height: 12px; /* Altura de la barra de desplazamiento horizontal */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
