.terms-modal {
  width: calc(100% - 4rem); 
  height: calc(100vh - 4rem); 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed; 
  top: 2rem;
  left: 2rem;
  z-index: 100; 
  background-color: rgba(0, 0, 0, 0); 
}

.terms-content {
  background-color: transparent;
  padding: 20px;
  border-radius: 8px;
  max-width: 650px;
  width: 100%;
  backdrop-filter: blur(40px);
  color: white;
  z-index: 50;
  height: 500px;
  overflow-y: scroll;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
}

.terms-content button {
  width: 50px;
  height: 20px;
  font-size: 20px;
  border: none;
  background: none;
  color: white;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.terminos p {
  font-size: 17px;
}

.terminos h2 {
  font-size: 19px;
}

