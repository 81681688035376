.main-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: white;
}
.main-detail-container h2 {
  color: aqua;
}
.pd_ctn {

  background-repeat: no-repeat;
}

.disponibilty {
  display: flex;
  justify-content: center;
}
.link_ {
  color: rgba(185, 4, 139, 0.9);
  font-size: 15px;
}
.link_ img {
  box-shadow: 8px 8px 10px rgba(0, 22, 150, 0.7);
}
.main_first {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-around;
}
.main-detail-container h1 {
  color: aqua;
}
.img-container img {
  box-shadow: 8px 8px 10px rgba(1, 13, 79, 58%);
  width: 250px;
  height: 250px;
}
.main-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  text-align: center;
  padding: 20px;
  width: 75%;

  row-gap: 20px;
  /*  background: linear-gradient(
    rgba(58, 246, 255, 0.2) 30%,
    rgba(185, 4, 139, 0.2) 60%
    );*/
}

.title-tablet {
  display: none;
}

.main-detail .price-detail {
  text-align: left;
  margin-bottom: 0px;
  font-weight: 700;
}

.main-detail .e-book {
  text-align: left;
  font-size: 14px;
  margin-top: 0px;
  font-weight: 500;
}

.main-detail hr {
  opacity: 0.5;
  border: 0.1px solid white;
  width: 75%;
}

.buying {
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
}

.img-container {
  width: 100%;
}

.buying .price-p {
  text-align: left;
  margin-top: -5px;
  font-size: 16px;
  font-weight: 700;
}

.download-button,
.buying button {
  width: 220px;
  height: 50px;
  border-radius: 20px;
  background: linear-gradient(
    rgba(58, 246, 255, 0.2) 30%,
    rgba(185, 4, 139, 0.2) 60%
  );
  outline: none;
  border: none;

  margin-top: 10px;
  font-weight: 700;
}

.download-button {
  color: white;
  cursor: pointer;
  width: 220px;
  margin-top: 10px;
  font-weight: 700;
  box-shadow: 8px 8px 10px rgba(1, 13, 79, 58%);
  color: white;
  font-weight: bold;
}

.detail-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.detail-content p {
  display: flex;
  justify-content: center;
}

.detail-content .modalBuy .emailModal {
  position: absolute;
  top: initial;
}

.price-detail {
  font-size: 14px;
  font-weight: 700;
}

.star-container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0px;
}

.star-container img {
  width: 15px;
  height: 15px;
}

.star-container p {
  font-size: 12px;
}

.extra {
  display: none;
}

.disponibilidad {
  margin: 50px auto 20px auto;
  font-size: 16px;
  font-weight: 600;
  font-weight: 600;
}

.interaction {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.interaction-box {
  display: flex;
  gap: 5px;
  align-items: center;
}

.interaction-box p {
  font-size: 12px;
}

.interaction-box img {
  width: 15px;
  height: 15px;
}

.book-description {
  text-align: justify;
  width: 90%;
}

.book-description .dropdown-button {
  width: 100%;
  height: 30px;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 25px;
}

.book-description .dropdown-button p {
  font-size: 16px;
  position: absolute;
  top: -10px;
  font-weight: 600;
}

.recomendation {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.book-recomendation {
  width: 80%;
  display: flex;
  overflow: scroll;
  margin: auto;
  margin: 0 auto 50px auto;
}
.book-recomendation::-webkit-scrollbar {
  display: none;
}

.book-recomendation .book-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.book {
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 auto;
  min-width: 190px;
}
.book .product_cart {
  cursor: pointer;
}
.cart_product {
  margin-left: -43px;
  margin-bottom: 4px;
  width: 35px;
}
.elipse_product {
  width: 50px;
}

.title-autor h4 {
  font-size: 14px;
}

.title-autor h6 {
  margin-top: -15px;
}

.type-price {
  margin-top: -15px;
}

.type-price p:nth-child(1) {
  font-weight: 500;
}

.type-price p:nth-child(2) {
  margin-top: -10px;
  font-weight: 700;
}

.book-recomendation .book-content {
  width: 90%;
  height: 120px;
  background-color: beige;
  display: flex;
}

.book p {
  font-size: 12px;
}

.carrito-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  background-color: rgba(4, 25, 88, 0.6);
}
.recomendation .arrowL {
  margin-top: -50px;
  width: 30px;
}

.recomendation .arrowR {
  margin-top: -50px;
  width: 30px;
}

@media (min-width: 768px) {
  .recomendation {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 100px;
  }
  .main-detail {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
  }

  .detail-content {
    width: 40%;
  }

  .img-container {
    width: 40%;
  }

  .extra {
    display: block;
    width: 40%;
  }

  .title-mobile {
    display: none;
  }

  .title-tablet {
    display: block;
  }

  .disponibilidad {
    margin: 50px auto 20px auto;
    font-weight: 600;
  }

  .book-description {
    text-align: left;
  }

  .interaction {
    gap: 30px;
  }

  .interaction-box img {
    width: 30px;
    height: 30px;
  }

  .interaction-box p {
    font-size: 14px;
  }

  .book-description {
    width: 80%;
  }

  .book-recomendation .book-content {
    width: 70%;
    height: 200px;
  }
}

@media (min-width: 992px) {
  .main-detail {
    width: 60%;
    justify-content: space-around;
    margin-bottom: 50px;
  }

  .img-container img {
    margin-top: 10px;
    width: 280px;
    height: 280px;
  }

  .detail-content {
    row-gap: 20px;
    margin-top: -15px;
  }

  .title-tablet h3 {
    font-size: 26px;
  }

  .main-detail .price-detail {
    font-size: 16px;
  }

  .main-detail .e-book {
    font-size: 16px;
  }

  .buying .price-p {
    font-size: 20px;
  }

  .star-container img {
    width: 30px;
    height: 30px;
  }
  .star-container p {
    font-size: 16px;
  }
  .buying button,
  .download-button {
    width: 250px;
    cursor: pointer;
  }

  .disponibilidad {
    margin: 100px auto 20px auto;

    font-size: 16px;
    font-weight: 600;
  }

  .interaction-box p {
    font-size: 16px;
  }

  .book-description span {
    font-size: 20px;
    text-align: justify;
  }

  .book-description {
    text-align: justify;
    width: 90%;
  }
  .book-recomendation .book .book-content {
    width: 75%;
    min-height: 300px;
  }
  .book-recomendation .book .book-content img {
    width: 100px;
    height: 100px;
  }

  .title-autor h4 {
    font-size: 14px;
  }

  .title-autor h6 {
    font-size: 16px;
  }

  .recomendation h3 {
    margin-top: 150px;
    margin-bottom: 30px;
  }

  .type-price p:nth-child(1) {
    font-size: 16px;
  }

  .type-price p:nth-child(2) {
    font-size: 14px;
  }
}
