.cartContainer {
  padding: 20px;
  background: linear-gradient(rgba(58, 246, 255, 0.2) 30%, rgba(185, 4, 139, 0.2) 60%);
  border-radius: 12px;
  color: white;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: fixed;
  right: 8%;
  top: 100px;
  width: 300px;
  height: 350px;
  box-shadow: 8px 8px 10px rgba(1, 13, 79, 58%);
  margin-right: 50px;
  z-index: 5;
}

/* Ocultar flechas de desplazamiento en WebKit (Chrome, Safari) */
.cartContainer::-webkit-scrollbar {
  width: 10px; /* Ancho de la barra de desplazamiento vertical */
}

.cartContainer::-webkit-scrollbar-button {
  display: none; /* Oculta las flechas de desplazamiento */
}

.cartContainer::-webkit-scrollbar-thumb {
  background-color: rgba(5, 0, 75, 0.87);
  border-radius: 20px;
}

.cartContainer::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

.deleteItem {
  cursor: pointer;
  border: none;
  background: none;
  color: grey;
  font-weight: bold;
}

.cartContainer h2 {
  text-align: center;
  margin: 0 0 20px 0;
  font-size: 24px;
}

.cartContainer .closeCart {
  width: 100%;
  cursor: pointer;
  text-align: end;
  margin: 0;
  font-weight: bold;
}

.total {
  display: flex;
  justify-content: space-between;
}

.cartItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cartItem img {
  width: 60px;
  height: 60px;
}

.cartItem p {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
}

.product_title {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  width: 120px;
}

.product_price {
  margin-left: 20px;
}

.compra_btn {
  background-image: linear-gradient(rgba(58, 246, 255, 0.2) 30%, rgba(185, 4, 139, 0.2) 60%);
  border-radius: 50px;
  width: 220px;
  margin: auto;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  box-shadow: 8px 8px 10px rgba(1, 13, 79, 58%);
}

.compra_btn button {
  height: 50px;
  background: none;
  border: none;
  font-size: 18px;
  color: white;
  text-decoration: none;
}

.cartContainer .cartItem,
.cartContainer .cartItem p,
.cartContainer .total {
  align-items: center;
  width: 100%;
}

.ctn_modal {
  width: 100%;
  height: 250px;
  position: absolute;
}

.emailModal {
  margin: 120px auto 0 auto;
  background: linear-gradient(rgba(58, 246, 255, 0.2) 30%, rgba(185, 4, 139, 0.2) 60%);
  max-width: 350px;
  position: fixed;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 8px 8px 10px rgba(1, 13, 79, 58%);
  z-index: 5;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.email_btn {
  color: white;
  width: 220px;
  height: 50px;
  background: linear-gradient(rgba(58, 246, 255, 0.2) 30%, rgba(185, 4, 139, 0.2) 60%);
  border-radius: 50px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  margin-left: 50px;
  font-weight: bold;
  font-size: 14px;
}

.modal-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(8px);
  z-index: 1;
}

.emailModal form {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  gap: 10px;
}

.emailModal input {
  border-radius: 6px;
  padding: 12px 16px;
  border: 1px solid #ccc;
}

.emailModal button {
  margin-top: 10px;
}

.emailModal h3 {
  color: white;
  margin: 20px auto 10px auto;
}

.terms {
  display: flex;
  color: white;
  justify-content: center;
  flex-direction: column;
}

.terms .check {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.terms_link {
  text-decoration: none;
  color: #3af6ff;
  font-weight: bold;
}

.terms .noCheck {
  color: white;
}

.terms p {
  font-size: 15px;
}

@media (max-width: 767px) {
  .emailModal {
    width: 300px;
    margin-top: 120px;
  }
  
  .email_btn {
    margin-left: 25px;
  }
  
  .cartContainer {
    margin-right: 15px;
  }
}

@media (max-width: 522px) {
  .cartContainer {
    margin-right: 8%;
    max-width: 250px;
  }
}

@media (min-width: 1024px) {
  .cartContainer {
    height: 400px;
  }
}
